import React from 'react';
import PropTypes from 'prop-types'; // use directly
import clsx from 'clsx';

import { withStyles, Theme } from '@material-ui/core/styles';

import * as dates from  '../../../utils/dates';
// import { date as dateLocalizer } from '../../../utils/localizers';



// }
// .ap-calendar-grid .ap-cell {
//     width: 40px;
//     height: 40px;
// }
// .ap-cell.ap-state-disabled {
//     color: #999;
//     filter: alpha(opacity=7);
//     opacity: .7;
// }
// .ap-cell, .ap-head-cell {
//     border: '0';
// }
// .ap-cell {
//     color: #333;
//     border-radius: 8px;
//     cursor: pointer;
//     line-height: normal;
//     border: 1px solid transparent;
// }


const styles = ((theme: Theme) => ({
  // drawer: {
  //   display: 'flex',
  //   width: theme.rightDrawerWidth,
  //   height: `calc(100% - ${theme.footerHeight}px)`,
  // },
  // thumbnailContainer: {
  //   display: 'flex',
  //   'flex-wrap': 'wrap',
  //   padding: '5px',
  //   'overflow-y': 'auto',
  // },
  // apNavView: {
  //   // table {
  //   'border-collapse': 'collapse',
  //   // }
  // },
  // apCalendarGrid: {
  //   height: 'auto',
  //   'border-collapse': 'collapse',
  // },
  // apCalendarRow: {
  // // tr {
  //   'border-bottom': '1pt solid rgb(203, 207, 213)',
  // // }
  // },
  // apHeadCell: {
  //   'font-family': "'Poppins', Arial, sans-serif",
  //   'font-weight': 400,
  //   'font-size': '12px',
  //   'text-align': 'center',
  //   'text-transform': 'uppercase',
  //   color: theme.grey,
  //   // .ap-cell, .ap-head-cell {
  //   // text-align: center;
  //   padding: '0',
  //   border: '0',
  //   'padding-bottom': '10px',
  //   // }
  // },
  apCell: {
    // width: '40px',
    // height: '40px',
    color: theme.greyMain, // #303740
    padding: '3px', // '9px 8px',
    // 'border-radius': '8px',
    cursor: 'pointer',
    'line-height': 'normal',
    border: '1px solid transparent',
    'text-align': 'center',
  },
  apBtn: {
    position: 'relative',
    // margin: '0 auto',
    // 'border-radius': '4px',
    'border-radius': '50%',
    // 'line-height': '24px',
    width: '44px',
    height: '44px',
    // margin: '4px auto',
    // padding: '10px 8px',
    padding: '8px',
    'font-size': '14px',
    'font-weight': '400',
    'line-height': '26px',
    'letter-spacing': 'normal',
    'font-family': '"Poppins",sans-serif',
    border: '1px solid transparent',
    // "&:hover": {
    //   'background-color': theme.greyLight,
    //   border: '1px solid rgb(203, 207, 213)',
    // },
  },
  apTimeSlotBtn: {
    position: 'relative',
    // margin: '0 auto',
    // 'border-radius': '4px',
    // 'border-radius': '8px',
    // 'line-height': '24px',
    // width: '44px',
    height: '60px',
    // margin: '4px auto',
    // padding: '10px 8px',
    // padding: '8px',
    'font-size': '12px',
    'font-weight': '400',
    'line-height': '18px',
    // 'letter-spacing': 'normal',
    'font-family': '"Poppins",sans-serif',
    border: '1px solid transparent',
    /* .tbk-grey-light */

    background: theme.greyLight, // #F8F8F8
    'border-radius': '8px',

    /* Inside auto layout */

    // flex: 'none',
    // order: 0,
    // 'align-self': 'stretch',
    // 'flex-grow': 1,
    // userSelect: 'none',
    // cursor: 'pointer',
    'text-align': 'center',
    // "&:hover": {
    //   'background-color': theme.greyLight,
    //   border: '1px solid rgb(203, 207, 213)',
    //   color: theme.greyMain,
    // },
    // transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
  },
  // apBtn: {

  // },
  apStateSelected: {
    background: theme.greyLight, // #F8F8F8
    border: '1px solid rgb(203, 207, 213)', // #CBCFD5
  },
  apCellOffRange: {
    visibility: 'hidden',
    color: theme.greyInactive, // #CBCFD5
    // "& :hover": {
    //   'background-color': 'transparent',
    //   'border-color':  'transparent',
    // }
  },
  apStateDisabled: {
    color: theme.greyInactive, // #CBCFD5
    background: 'white',
    border: '0',
    'pointer-events': 'none',
    // filter: 'alpha(opacity=7)',
    // opacity: '.7',
  },
  // apStateHasBooking: {
  //   "& div": {
  //     "&:after": {

  //     content: ' ',
  //     display: 'block',
  //     width: '4px',
  //     height: '4px',
  //     'background-color': 'red',
  //     position: 'absolute',
  //     }
  //   }
  // },
  apBtnHasBooking: {
    // content: ' ',
    display: 'block',
    width: '4px',
    height: '4px',
    'background-color': theme.red,
    'border-radius': '50%',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-2px, 0)',

  },
  apTSStateSelected: {
    'background-color': theme.red,
    color: theme.whiteBasic,
    "&:not($apTSStateDisabled) + $apTSStateSelected": {
      "&:after": {
        content: " ' ' " ,
        position: 'absolute',
        height: '60px',
        width: '32px',
        left: '-21px',
        top: '-1px',
        background: theme.red, //'#E94E32',
        'border-radius': '8px',
        'z-index': -1,
        // 'background-color': 'green'
      }
    },

  },

  apTSStateUnavailable: {
    'background-color': theme.grey,
    color: theme.whiteBasic,
    "&:not($apTSStateDisabled) + $apTSStateUnavailable": {
      "&:after": {
        content: " ' ' " ,
        position: 'absolute',
        height: '60px',
        width: '32px',
        left: '-21px',
        top: '-1px',
        background: theme.grey,
        'border-radius': '8px',
        'z-index': -1,
        // 'background-color': 'green'
      }
    },
    // "& :hover": {
    //   'background-color': theme.grey,
    //   color: theme.whiteBasic,
    // }
  },

  apTSStateDisabled: {
    'background-color': theme.greyLight,
  },
  // apBtn: {

// .rw-btn, .rw-filter-input, .rw-input, .rw-input-reset {
//     color: inherit;
//     padding: '0';
//     margin: 0;
//     border: none;
//     box-shadow: none;
//     background: none;
//     background-image: none;
//     font-family: inherit;
//     font-size: inherit;
//     line-height: inherit;
//     -ms-touch-action: manipulation;
//     touch-action: manipulation;
// }
  // },
  apNow: {
    color: theme.red,
  }
}));

const VIEW_UNITS = ['month', 'year', 'decade', 'century'];

// function clamp(date, min, max){
//   return dates.max(dates.min(date, max), min)
// }

// Is AvailabilityDayPickerView extraneous?
class AvailabilityView extends React.Component {
  render() {
    let { className } = this.props;

    return (
      <table
        {...this.props}
        role='grid'
        tabIndex='-1'
        className={clsx(
          className,
          'ap-nav-view',
          'ap-calendar-grid'
        )}
      />
    )
  }
}


class AvailabilityViewCell extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    style: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    // today: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    // selected: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.bool]),
    // focused: PropTypes.instanceOf(Date),
    date: PropTypes.instanceOf(Date),
    now: PropTypes.instanceOf(Date),
    min: PropTypes.instanceOf(Date),
    max: PropTypes.instanceOf(Date),
    /**
     * The day of month for the first (inclusive) enabled day.
     */
    // enabledDayStart: PropTypes.number,
    /**
     * The day of month for the last (inclusive) enabled day.
     */
    // enabledDayEnd: PropTypes.number,
    hasBooking: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    unit: PropTypes.oneOf(['day', ...VIEW_UNITS]),
    viewUnit: PropTypes.oneOf(VIEW_UNITS),
    onChange: PropTypes.func, //.isRequired
  };

  isEqual(date) {
    return dates.eq(this.props.date, date, this.props.unit)
  }

  // isEmpty() {
  //   let { unit, min, max, date } = this.props;
  //   return !dates.inRange(date, min, max, unit)
  // }

  isNow() {
    return this.isEqual(this.props.now)
  }

  isFocused() {
    return this.isEqual(this.props.focused)
  }

  // isSelected() {
  //   return this.isEqual(this.props.selected)
  // }

  isOffView() {
    let { viewUnit, min/*focused*/, date } = this.props;
    return viewUnit && dates.month(date) !== dates.month(min/*focused*/);
  }

  // isDayEnabled(day: number) {
  //   return day >= enabledDayStart && day <= enabledDayEnd;
  // }

  hasBooking() {
    return this.props.hasBooking;
  }

  isHighlighted() {
    return this.props.isHighlighted;
  }

  handleChange = () => {
    let { onChange/*, min, max*/, date } = this.props;
    // onChange(clamp(date, min, max));
    onChange(date);
  }

  render()  {
    let { children, id, label, disabled } = this.props;

    // if (this.isEmpty()) {
    //   return <td className='ap-empty-cell' role='presentation'>&nbsp;</td>
    // }

    return (
      <td
        role='gridcell'
        id={id}
        title={label}
        aria-label={label}
        aria-readonly={disabled}
        // aria-selected={this.isSelected()}
        className={clsx(
            // this.props.className,
            this.props.classes.apCell,
            this.isOffView() && this.props.classes.apCellOffRange, //'ap-off-range',
            this.isFocused() && this.props.classes.apStateFocused, //'ap-state-focus',
            // this.isSelected() && this.props.classes.apStateSelected, // && this.props.classes.apStateDisabled, //'ap-state-selected',
            // this.hasBooking() && this.props.classes.apStateHasBooking, // && this.props.classes.apStateDisabled, //'ap-state-selected',
            // this.isEmpty() && 'ap-empty-cell'
          )}
        style={this.props.style}
      >
        <div
          aria-labelledby={id}
          onClick={this.handleChange}
          className={clsx(
            this.props.classes.apBtn,
            // this.isSelected() && this.props.classes.apStateSelected,
            this.isHighlighted() && !disabled && this.props.classes.apStateSelected,
            this.isNow() && this.props.classes.apNow, //'ap-now'
            disabled && this.props.classes.apStateDisabled,
          )}
        >
          {children}
          {this.hasBooking() && !disabled && (<div className={this.props.classes.apBtnHasBooking} />)}
        </div>
      </td>
    )
  }
}

class AvailabilityViewTimeSlot extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    style: PropTypes.object,
    id: PropTypes.string,
    label: PropTypes.string,
    // today: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    // selected: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.bool]),
    // focused: PropTypes.instanceOf(Date),
    date: PropTypes.instanceOf(Date),
    // now: PropTypes.instanceOf(Date),
    // min: PropTypes.instanceOf(Date),
    // max: PropTypes.instanceOf(Date),
    /**
     * The day of month for the first (inclusive) enabled day.
     */
    // enabledDayStart: PropTypes.number,
    /**
     * The day of month for the last (inclusive) enabled day.
     */
    // enabledDayEnd: PropTypes.number,
    // hasBooking: PropTypes.bool,
    // isHighlighted: PropTypes.bool,
    // unit: PropTypes.oneOf(['day', ...VIEW_UNITS]),
    // viewUnit: PropTypes.oneOf(VIEW_UNITS),
    // date={v.date}
    slotType: PropTypes.string,
    onClick: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  // isEqual(date) {
  //   return dates.eq(this.props.date, date, this.props.unit)
  // }

  // isEmpty() {
  //   let { unit, min, max, date } = this.props;
  //   return !dates.inRange(date, min, max, unit)
  // }

  // isNow() {
  //   return this.isEqual(this.props.now)
  // }

  // isFocused() {
  //   return this.isEqual(this.props.focused)
  // }

  isSelected() {
    return this.props.slotType === 'IN_SESSION' ||
      this.props.slotType === 'AVAILABLE';
    // return this.isEqual(this.props.selected)
  }

  isUnavailable() {
    return this.props.slotType === 'IS_NOT_AVAILABLE_FOR_BOOKING';
  }

  // isOffView() {
  //   let { viewUnit, focused, date } = this.props;
  //   return viewUnit && dates[viewUnit](date) !== dates[viewUnit](focused);
  // }

  // isDayEnabled(day: number) {
  //   return day >= enabledDayStart && day <= enabledDayEnd;
  // }

  // hasBooking() {
  //   return this.props.hasBooking;
  // }

  // isHighlighted() {
  //   return this.props.isHighlighted;
  // }

  // handleChange = () => {
  //   let { onChange, min, max, date } = this.props;
  //   onChange(clamp(date, min, max));
  // }

  handleClick = () => {
    let { date, onClick } = this.props;
    onClick(date);
  }

  render()  {
    let { children, id, /*label, disabled*/ } = this.props;

    // if (this.isEmpty()) {
    //   return <td className='ap-empty-cell' role='presentation'>&nbsp;</td>
    // }

      //<td
      //  role='gridcell'
      //  id={id}
      //  title={label}
      //  aria-label={label}
      //  aria-readonly={disabled}
      //  aria-selected={this.isSelected()}
      //  className={clsx(
      //      'ap-grid-cell',
      //      // this.props.className,
      //      this.props.classes.apCell,
      //      this.isOffView() && this.props.classes.apCellOffRange, //'ap-off-range',
      //      this.isFocused() && this.props.classes.apStateFocused, //'ap-state-focus',
      //      this.isSelected() && this.props.classes.apStateSelected, // && this.props.classes.apStateDisabled, //'ap-state-selected',
      //      // this.hasBooking() && this.props.classes.apStateHasBooking, // && this.props.classes.apStateDisabled, //'ap-state-selected',
      //      this.isEmpty() && 'ap-empty-cell'
      //    )}
      //  style={this.props.style}
      //>
      //</td>
    return (
        <td
          aria-labelledby={id}
          onClick={this.handleClick}
          className={clsx(
            this.props.classes.apTimeSlotBtn,
            this.props.disabled && this.props.classes.apTSStateDisabled,
            this.isSelected() && this.props.classes.apTSStateSelected,
            this.isUnavailable() && this.props.classes.apTSStateUnavailable,
            // this.isSelected() && this.props.classes.apStateSelected,
            // this.isSelected() || this.isHighlighted() && this.props.classes.apStateSelected,
            // this.isNow() && this.props.classes.apNow //'ap-now'
          )}
        >
          {this.props.disabled ? null : children}
          {/*{this.props.slotType}*/}
          {/*{this.hasBooking() && (<div className={this.props.classes.apBtnHasBooking} />)}*/}
        </td>
    )
  }
}

AvailabilityView.Row = props => <tr role='row' {...props} />;
AvailabilityView.Cell = withStyles(styles)(AvailabilityViewCell);
AvailabilityView.TimeSlot = withStyles(styles)(AvailabilityViewTimeSlot);

export default AvailabilityView; //CalendarView;
