import { isPlainObject } from 'is-plain-object';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
  return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

/**
 * Detects if the device is iOS.
 * @param {Message} a The first message.
 * @param {Message} b The second message.
 * @returns {int} -1 if a comes before b, 1 if a comes after b, 0 if equal.
 * @private
 */
export const isIosDevice = (() => {
  return typeof window !== 'undefined' &&
  window.navigator &&
  window.navigator.platform &&
  (/iP(ad|hone|od)/.test(window.navigator.platform) ||
  (window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1));
})();

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
  const val = obj[key];
  if (typeof val !== 'undefined') {
    target[key] = removeUndefineds(val);
  }
  }

  return target as T;
}

// export async function getDeviceInfo() {
//   const devices = await navigator.mediaDevices.enumerateDevices();

//   return {
//     audioInputDevices: devices.filter(device => device.kind === 'audioinput'),
//     videoInputDevices: devices.filter(device => device.kind === 'videoinput'),
//     audioOutputDevices: devices.filter(device => device.kind === 'audiooutput'),
//     hasAudioInputDevices: devices.some(device => device.kind === 'audioinput'),
//     hasVideoInputDevices: devices.some(device => device.kind === 'videoinput'),
//   };
// }

export function getDocumentHeight() {
  var body = document.body,
  html = document.documentElement;

  var height = Math.max( body.scrollHeight, body.offsetHeight,
  html.clientHeight, html.scrollHeight, html.offsetHeight );

  return height;
}

export function getViewportHeight() {
  // const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  const vh = window.innerHeight * (window.visualViewport?.scale || 1);

  return vh;
}
export function getDimentions() {
  var w = window,
  d = document,
  docElem = d.documentElement,
  body = d.getElementsByTagName('body')[0],
  width = w.innerWidth || docElem.clientWidth || body.clientWidth,
  height = w.innerHeight || docElem.clientHeight || body.clientHeight;

  return { width, height };
}


// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: 'camera' | 'microphone') {
  const permissionName = name as PermissionName; // workaround for https://github.com/microsoft/TypeScript/issues/33923

  if (navigator.permissions) {
  try {
    const result = await navigator.permissions.query({ name: permissionName });
    return result.state === 'denied';
  } catch {
    return false;
  }
  } else {
  return false;
  }
}

export function chunk(array: any, chunkSize: any) {
  var index = 0,
  length = array ? array.length : 0,
  result = [];

  chunkSize = Math.max(+chunkSize || 1, 1);

  while (index < length) {
    result.push(array.slice(index, index += chunkSize));
  }

  return result;
}

// export const toQueryString = obj => {
export function toQueryString<T>(obj: T): string {
  // var parts = [];

  return Object.entries(obj).reduce((acc, [key, val])=>{
    if (Array.isArray(val)) {
      val.forEach(e => acc += (acc ? '&': '') + key + '=' + e);
    } else {
      acc += (acc ? '&': '') + encodeURIComponent(key) + '=' + encodeURIComponent(val);
    }

    return acc;
  }, '');
};

export function makeUrlString(str: string = ''): string {
  return str.toLowerCase().replace(/[\s\\/]/g, match => match === '\\' || match === '/' ? '' : '-');
}

// Copy to clipboard

export function copy(text: string): boolean {
  var selected: boolean | Range = false,
    selection,
    // range,
    elem,
    success = false;

  try {
    selected = document!.getSelection()!.rangeCount > 0 // Check if there is any content selected previously
      ? document!.getSelection()!.getRangeAt(0) // Store selection if found
      : false;

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selection = window.getSelection();

    elem = document.createElement('textarea');
    elem.textContent = text;

    // For iOS
    elem.contentEditable = 'true';
    elem.setAttribute('readonly', '');

    // Place in top-left corner of screen regardless of scroll position
    elem.style.position = 'fixed';
    elem.style.top = '0';
    elem.style.left = '0';

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    elem.style.width = '1px';
    elem.style.height = '1px';

    // We don't need padding, reducing the size if it does flash render.
    elem.style.padding = '0';

    // Clean up any borders.
    elem.style.border = 'none';
    elem.style.outline = 'none';
    elem.style.boxShadow = 'none';

    document.body.appendChild(elem);


    elem.select();

    elem.setSelectionRange(0, elem.value.length); // A big number, to cover anything that could be inside the element

    success = document.execCommand('copy');
  } finally {
    if (elem) {
      document.body.removeChild(elem);
    }

    if (selected) {                                   // If a selection existed before copying
      document!.getSelection()!.removeAllRanges();    // Unselect everything on the HTML document
      document!.getSelection()!.addRange(selected);   // Restore the original selection
    }
  }

  return success;
}

/**
 * Wraps promise so it could be cancelled
 * Taken from here https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 * @return {Object} with promise and cancel method
 */

export function makeCancelable(promise: any) {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
  promise.then(
    (val: any) => hasCanceled_ ? reject({isCanceled: true}) : resolve(val),
    (error: any) => hasCanceled_ ? reject({isCanceled: true}) : reject(error)
  );
  });

  return {
  promise: wrappedPromise,
  cancel() {
    hasCanceled_ = true;
  }
  };
}

export function debounce(func: any, wait: number, immediate?: any) {
  var timeout: any;

  return function() {

  // @ts-ignore
  var context = this, args = arguments;

  var later = function() {
    timeout = null;

    if (!immediate) {
    func.apply(context, args);
    }
  };

  var callNow = immediate && !timeout;

  clearTimeout(timeout);

  timeout = setTimeout(later, wait);

  if (callNow) {
    func.apply(context, args);
  }
  };
};

// // Originally inspired by  David Walsh (https://davidwalsh.name/javascript-debounce-function)

// // Returns a function, that, as long as it continues to be invoked, will not
// // be triggered. The function will be called after it stops being called for
// // `wait` milliseconds.
// export function debounce(func: any, wait: number) {
//   let timeout: any;

//   return function executedFunction(...args: any[]) {
//     const later = () => {
//       clearTimeout(timeout);
//       func(...args);
//     };

//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//   };
// };

// module.exports = copy;

