import React, { PropsWithChildren, useState, useEffect, useRef, FormEvent } from 'react';
import { ScrollView } from 'react-native';
import clsx from 'clsx';
import { useAppState } from '../../state';

import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import RoundedContainer from '../RoundedContainer/RoundedContainer';

// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';

// import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Typography from '@material-ui/core/Typography';

import UserPlaceholder from '../../icons/UserPlaceholder80';
// import LocationIcon from '../../icons/LocationIcon';
// import CloseIcon from '../../icons/Close2Icon';
// import ArrowRightIcon from '../../icons/ArrowRightIcon';
// import PlusIcon from '../../icons/PlusIcon';
// import UserPlaceholderInPerson from '../../icons/UserPlaceholderInPerson';
// import UserPlaceholderOnline from '../../icons/UserPlaceholderOnline';
// import PhoneIcon from '../../icons/PhoneIcon';
// import CalendarIcon from '../../icons/CalendarIcon';
// import QuestionOIcon from '../../icons/QuestionOIcon';
// import Calendar64Icon from '../../icons/Calendar64Icon';
// import Location64Icon from '../../icons/Location64Icon';
// import SessionOnline64Icon from '../../icons/SessionOnline64Icon';
// import WoKickboxingIcon from '../../icons/WoKickboxingIcon';
// import CopyIcon from '../../icons/CopyIcon';
// import FullStarIcon from '../../icons/FullStarIcon';
import LoaderSpinnerIcon from '../../icons/LoaderSpinnerIcon';

// import BottomNavigation from '../BottomNavigation/BottomNavigation';
import StarsRatingBlock from '../StarsRatingBlock/StarsRatingBlock';
import RateSessionDialog from '../RateSessionDialog/RateSessionDialog';

// import SessionInPersonListItemIcon from './SessionInPersonListItemIcon';
// import SessionOnlineListItemIcon from './SessionOnlineListItemIcon';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useParams, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  gutterBottom: {
    'margin-bottom': '.5rem',
  },
  // gutterBottomBase: {
  //   'margin-bottom': '1rem',
  // },
  // gutterBottomExtraLarge: {
  //   'margin-bottom': '1.5em',
  // },
  // gutterTopDoubleMedium: {
  //   'margin-top': '2em',
  // },
  // gutterBottomDoubleMedium: {
  //   'margin-bottom': '2em',
  // },
  // gutterTopFull: {
  //   'margin-top': '64px'
  // },
  // gutterBottomExtraExtraLarge: {
  //   'margin-bottom': '80px'
  // },
  // passcodeContainer: {
  //   'min-height': '120px',
  // },
  // bottomCtaContainer: {
  //   position: 'fixed',
  //   bottom: '100px',
  //   left: '0',
  //   'background-color': theme.whiteBasic,
  //   'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
  //   'z-index': 1099
  // },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  // whiteBasicColor: {
  //   color: theme.whiteBasic,
  // },
  // trueblackBasicColor: {
  //   color: theme.trueblackBasic,
  // },
  // redColor: {
  //   color: theme.red,
  // },
  // greyColor: {
  //   color: theme.grey,
  // },
  greyMainColor: {
    color: theme.greyMain,
  },
  // greenMainColor: {
  //   color: theme.greenMain,
  // },
  // root: {
  //   'justify-content': 'center',
  //   color: theme.trueblackBasic,
  //   'min-width': '24px'
  // },
  // rootArrow: {
  // 	color: theme.red,
  // 	'min-width': '24px'
  // },
  // closeBackgroundSelection: {
  //   cursor: 'pointer',
  //   display: 'flex',
  //   background: 'transparent',
  //   border: '0',
  //   padding: '0',
  // },

  // linkLikeButton: {
  //   'text-decoration': 'underline'
  // },

  userProfilePictureContainer: {
    width: '80px',
    height: '80px',
    margin: '0 auto',
    'border-radius': '50%',
    overflow: 'hidden',

    // width: '64px',
    // height: '64px',
    // // margin: 0,
    // // borderWidth: 1px;
    // // border: 2px solid #e5e5e5;
    // 'border-radius': '50%',
    // // margin: 0 auto;
  },

  userPictureImg: {
    width: '100%',
    height: 'auto',
    'text-indent': '100%',
    'white-space': 'nowrap',
  },

  submitButtonContainer: {
    position: 'relative',
    width: '100%',
    'margin-bottom': '40px',
    // 'margin-right': '1em',
  },
  submitButtonLoadingSpinner: {
    '& > svg': {
      right: '11px',
      top: '20px',
      position: 'absolute',
      'pointer-events': 'none',
      animation: '$spin 1s linear infinite',
      display: 'inline-block',
      'vertical-align': 'text-bottom',
      fill: 'white',
      'z-index': 2,
    },
  },
  '@keyframes spin': {
    '100%': {
      'webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
}));

// // TODO: make component
// const GreyRoundedBox = withStyles((theme) => ({
//   root: {
//     height: "100%",
//     // 'border-top-left-radius': '8px',
//     // 'border-top-right-radius': '8px',
//     'border-radius': '8px',
//     'background-color': theme.greyLight,
//     // 'padding-top': 10,
//     // 'padding-bottom': 10,
//   }
// }))(Box);

// // Fixed number of columns
// const gridContainer = {
//   display: "grid",
//   gridTemplateColumns: "repeat(2, 1fr)"
// };

// // Variable number of columns
// const gridContainer2 = {
//   display: "grid",
//   gridAutoColumns: "1fr",
//   gridAutoFlow: "column"
// };

// const gridItem = {
//   height: "100%",
//   // margin: "8px",
//   // border: "1px solid red"
// };

interface RateSessionProps {
  // open: boolean;
  trainerName?: string;
  // sessionType: string;
  // onClick(): void;
  // onClose(): void;
}

export default function RateSession(props: PropsWithChildren<RateSessionProps>) {
  const classes = useStyles();
  const { user, booking, setBooking, getBooking, rateCustomer, isFetching /*, isAuthReady*/ } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const { BookingId } = useParams<{ BookingId?: string }>();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  const [bookingId, setBookingId] = useState<string | null>(null);
  const [userStrengthRating, setUserStrengthRating] = useState(0);
  const [userEnduranceRating, setUserEnduranceRating] = useState(0);
  const [userMobilityRating, setUserMobilityRating] = useState(0);
  const [userOverallRating, setUserOverallRating] = useState(0);
  // const [authError, setAuthError] = useState<Error | null>(null);
  const [rateSessionError, setRateSessionError] = useState('');

  const [rateSessionOpen, setRateSessionOpen] = useState(false);

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  const textInputRef = useRef<HTMLTextAreaElement>(null);

  // const setUserRating = (rating) => {
  //   // console.log(index);
  //   setDrive(index);
  // };

  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    if (BookingId) {
      setBookingId(BookingId);

      getBooking(Number(BookingId));
    } else {
      setBookingId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, BookingId]);

  useEffect(() => () => setBooking(null), []);

  const handleSubmit = React.useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const comment = textInputRef.current?.value || ''; //(e.target as HTMLInputElement)[0].value; // e.target.inputs;

      setRateSessionError('');

      rateCustomer(
        user?.id!,
        booking?.id!,
        userStrengthRating,
        userEnduranceRating,
        userMobilityRating,
        userOverallRating,
        comment
      )
        .then(() => setRateSessionOpen(true))
        .catch(err => {
          if (err.code === 422) {
            setRateSessionError(err.message);
          }

          setRateSessionOpen(true);

          // if (e.code === 413) {
          // } else {
          //   setFileSendError('There was a problem uploading the file. Please try again.');
          // }
        })
        .finally(() => {
          // setRateSessionOpen(true);
        });
    },
    [
      user,
      booking,
      userStrengthRating,
      userEnduranceRating,
      userMobilityRating,
      userOverallRating,
      rateCustomer,
      setRateSessionOpen,
      setRateSessionError,
    ]
  );

  const handleRateSessionDialogClose = () => {
    setRateSessionOpen(false);
    history.push({ pathname: `${location?.state?.from ? location?.state?.from : '/sessions/' + booking?.id}` });
    // setMenuOpen(false);
  };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  return (
    <>
      <ScrollToTopOnMount />
      <RoundedContainer>
        <form onSubmit={handleSubmit}>
          <ScrollView
            // key={`${this.props.scrollEnabled}`}
            // ref={this.props.refCallback}
            // onScroll={this.props.onMomentumScrollEnd}
            style={{
              overflow: 'scroll',
              width: '100%',
              // height: '100%',
              // padding: '24px 16px',
              // 'background-color': 'rgb(255, 255, 255)',
              // 'border-radius': '8px',
              // overflow: 'scroll'
              marginTop: '64px',
              marginBottom: '24px',
              flexGrow: 1,
              // ml={1}
            }}
          >
            <div style={{ marginBottom: '8px', textAlign: 'center' }}>
              {/*<Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={8}
        mb={3}
      >*/}
              {/*<Box
          flexGrow="1"
          // ml={1}
          textAlign="center"
        >*/}
              {/*<Box
            display="flex"
            alignItems="center"
            // justifyContent="space-between"
            mb={1}
          >
            <Box
              display="flex"
              className={clsx(classes.greyColor)}
              mr={1}
            >
              <WoKickboxingIcon />
            </Box>

            <Typography variant="caption" color="primary" className={classes.gutterBottom}>
                Rate the Session With
            </Typography>
          </Box>*/}

              <Typography
                variant="caption"
                color="primary"
                className={classes.gutterBottom}
                style={{
                  display: 'inline-block',
                  color: 'rgb(107, 113, 120)' /* #6B7178 */,
                  textTransform: 'uppercase',
                }}
              >
                Rate the Session With
              </Typography>

              <Typography variant="h4" color="primary" className={''} style={{ marginBottom: '16px' }}>
                {booking?.user?.firstName}
              </Typography>

              <div style={{ marginBottom: '40px' }}>
                {booking?.user?.userDetail.pictureUrl ? (
                  <div className={classes.userProfilePictureContainer}>
                    <img
                      className={classes.userPictureImg}
                      itemProp="image"
                      src={booking?.user.userDetail.pictureUrl + '?size=128x128'}
                      srcSet={`${booking?.user.userDetail.pictureUrl}?size=128x128 1x, ${booking?.user.userDetail.pictureUrl}?size=256x256 2x`}
                      alt={`Customer profile`}
                    />
                  </div>
                ) : (
                  <UserPlaceholder />
                )}
              </div>

              {/*<Typography variant="body1" className={classes.greyColor} style={{ 'margin-bottom': 40 }} >
              32, male
          </Typography>*/}

              <StarsRatingBlock
                className={clsx(classes.gutterBottom, classes.greyMainColor)}
                title="Strength"
                // rating={4}
                onRatingSet={setUserStrengthRating}
              />

              <StarsRatingBlock
                className={clsx(classes.gutterBottom, classes.greyMainColor)}
                title="Endurance"
                onRatingSet={setUserEnduranceRating}
              />

              <StarsRatingBlock
                className={clsx(classes.gutterBottom, classes.greyMainColor)}
                title="Mobility"
                onRatingSet={setUserMobilityRating}
              />

              <StarsRatingBlock
                className={clsx(classes.gutterBottom, classes.greyMainColor)}
                title="How was the session?"
                onRatingSet={setUserOverallRating}
              />

              {/*</Box>*/}
              {/*</Box>*/}
            </div>

            {/*<GreyRoundedBox
        // display="flex"
        pt={2}
        pl={2}
        pr={2}
        pb={2}
        mb={3}
        sx={{
          padding: 16
        }}
        style={{
          height: 144
        }}
      >

        <Typography variant="subtitle2" color="primary" style={{ color: '#6B7178' }}>
          Please add some notes
        </Typography>

      </GreyRoundedBox>*/}

            <Container maxWidth="sm" className={classes.gutterBottom}>
              <TextareaAutosize
                ref={textInputRef}
                maxRows={4}
                aria-label="Notes"
                placeholder="Please add some notes"
                style={{
                  width: '100%',
                  height: '144px',
                  border: '0',
                  borderRadius: '8px',
                  backgroundColor: 'rgb(248, 248, 248)', // #F8F8F8
                  padding: '16px',
                  marginBottom: '24px',
                  color: 'rgb(107, 113, 120)', // #6B7178
                  fontSize: '16px',
                  fontFamily: 'Poppins',
                  fontWeight: 400,
                }}
                // defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
              />

              <Grid
                container
                justifyContent="center"
                // justifyContent="flex-end"
                className={classes.submitButtonContainer}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!userStrengthRating || !userEnduranceRating || !userMobilityRating || !userOverallRating}
                  // disabled={!password.length}
                  className={classes.submitButton}

                  // onClick={() => setRateSessionOpen(true)}
                >
                  Submit rating
                </Button>
                {isFetching && (
                  <Box
                    display="flex"
                    className={clsx(classes.submitButtonLoadingSpinner)}
                    sx={{
                      marginRight: '4px',
                    }}
                  >
                    <LoaderSpinnerIcon />
                  </Box>
                )}
              </Grid>
            </Container>
          </ScrollView>
        </form>
      </RoundedContainer>
      <RateSessionDialog open={rateSessionOpen} text={rateSessionError} onClose={handleRateSessionDialogClose} />
    </>
  );
}
