import React, { useState } from 'react';
import {
  View,
  // ScrollView,
  StyleSheet,
} from 'react-native';
import clsx from 'clsx';
import { useAppState } from '../../state';

import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';

import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { /*InputLabel,*/ Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
import RoundedContainer from '../RoundedContainer/RoundedContainer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

// import SlidingUpPanel from 'rn-sliding-up-panel';
// import { Drawer as TruBeDrawer } from '../Drawer/Drawer';
import { AddAreaPopup } from '../AddAreaPopup/AddAreaPopup';

import LocationIcon from '../../icons/LocationIcon';
// import ArrowRightIcon from '../../icons/ArrowRightIcon';
import PlusIcon from '../../icons/PlusIcon';

import BottomNavigation from '../BottomNavigation/BottomNavigation';

import { makeStyles } from '@material-ui/core/styles';
// import { useLocation, useHistory } from 'react-router-dom';

// import { getViewportHeight } from '../../utils';

import { areas } from '../../areas';

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  gutterBottom: {
    'margin-bottom': '1em',
  },
  gutterTopDoubleMedium: {
    'margin-top': '2em',
  },
  gutterBottomDoubleMedium: {
    'margin-bottom': '2em',
  },
  gutterBottomExtraExtraLarge: {
    'margin-bottom': '80px',
  },
  passcodeContainer: {
    'min-height': '120px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  addAreaCtaButton: {
    // 'margin-bottom': 16,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  whiteBasicColor: {
    color: theme.whiteBasic,
  },
  trueblackBasicColor: {
    color: theme.trueblackBasic,
  },
  redColor: {
    color: theme.red,
  },
  greyColor: {
    color: theme.grey,
  },
  greyMainColor: {
    color: theme.greyMain,
  },
  root: {
    'justify-content': 'center',
    color: theme.trueblackBasic,
    'min-width': '24px'
  }
}));


// const styles = {
//   container: {
//     flex: 1,
//     'background-color': 'rgb(255, 255, 255)',
//     'align-items': 'center',
//     'justify-content': 'center',
//     'border-radius': '8px',
//     height: '100%',
//   },
// };

// const panelStyles = {
//   backdrop: {
//       position: 'fixed',
//       'z-index': 1000,
//   },
//   container: {
//     position: 'fixed',
//     // flex: 1,
//     // 'background-color': 'rgb(255, 255, 255)',
//     // 'align-items': 'center',
//     // 'justify-content': 'center',
//     'z-index': 1000,
//   },
// };

//interface AreasPageProps {
  // open: boolean;
  // onClose(): void;
//}

// const height = getViewportHeight();

export default function AreasPage() {
  const classes = useStyles();
  const { user, isFetching } = useAppState();
  // const history = useHistory();
  // const location = useLocation<{ from: Location }>();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  const [addAreaDrawerOpen, setAddAreaDrawerOpen] = useState<boolean>(false);
  // const [authError, setAuthError] = useState<Error | null>(null);

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   login();
  // };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  const handleAddAreaClick = React.useCallback(() => {
    setAddAreaDrawerOpen(true);
  }, [setAddAreaDrawerOpen]);


  const closeAreaDrawer = React.useCallback(() => {
    setAddAreaDrawerOpen(false);
  }, [setAddAreaDrawerOpen]);

  // const panelRef = useRef<SlidingUpPanel>(null);

  const userAreas = user && user.areas ? user.areas.map(area => areas.find(v => v.outward === area)) : []

  return (
    <>
    <ScrollToTopOnMount />
    <div>
    <Container style={{ 'paddingTop': '32px' }}>
      <Typography
        variant="h2"
        className={clsx(classes.gutterBottomDoubleMedium, classes.greyMainColor)}
        style={{ 'marginTop': '0', 'marginBottom': '32px' }}
      >
        AREAS COVERED
      </Typography>
    </Container>

    <RoundedContainer
      style={{
        // height: '100%',

        height: 'calc(100% - 104px)',
        // 'min-height': '-webkit-fill-available',
        // 'margin-bottom': 'env(safe-area-inset-bottom)',
        'padding-bottom': '82px',
        // 'padding-bottom': '86px'
      }}
      innerContainerStyle={{
        overflow: 'scroll',
        // 'padding-bottom': 16
      }}
    >

      <Container style={{ 'paddingTop': '32px', 'paddingBottom': '96px' }}>
      <Typography variant="h3" className={clsx(classes.gutterBottom, classes.greyColor)}>
          {userAreas.length ? `${userAreas.length} postcodes covered:` : 'No postcodes covered.'}
      </Typography>

      {/*<Container maxWidth="sm" disableGutters>*/}
      <Grid container justifyContent="flex-end" style={{ flexShrink: 0 }}>
        <List
          component="nav"
          aria-label="mailbox folders"
          disablePadding
          // className={classes.gutterBottomExtraExtraLarge}
          style={{
            width: '100%',
            // 'margin-bottom': 96
          }}
        >
          {userAreas.map(area => (
            <>
            <ListItem
              button
              style={{
                paddingLeft: "0",
                paddingRight: "0",
                // justifyContent: "space-between",
                // flex: "1",
              }}
              key={area?.outward}
            >
              <ListItemIcon
                classes={{
                  root: classes.root,
                }}
              >
                <LocationIcon />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: "button",
                  display: "inline",
                  color: "primary"
                }}
                secondaryTypographyProps={{
                  variant: "subtitle1",
                  display: "inline",
                  color: "primary"
                }}
                primary={`${area?.outward}, `}
                secondary={area && area.town ? area.town : area && area.county}
                style={{ marginLeft: '16px' }}
              />
            </ListItem>
            <Divider />
            </>
          ))}
          <Divider light />
        </List>
      {/*</Container>*/}
      </Grid>


      </Container>

    </RoundedContainer>
    </div>
    <View style={styles.ctaContainer}>
      <Button
        variant="contained"
        color="primary"
        type="submit"
        startIcon={<PlusIcon />}
        onClick={handleAddAreaClick}
        // disabled={!password.length}
        className={classes.addAreaCtaButton}
      >
        Add Area
      </Button>
    </View>
    <BottomNavigation />

    {/*{addAreaDrawerOpen && (*/}
    <AddAreaPopup
      // disabled={false}
      title="Add area"
      isFetching={isFetching}
      isOpen={addAreaDrawerOpen}
      allowDragging={true}
      // activeState={this.props.bookingPanelState}
      // bookingDate={this.state.bookingDate}
      // onDateSelect={this.handleDateTimeChange}
      // onDateSelect={this.handleDirectBookingTimeChange}
      onClose={closeAreaDrawer}
      // onNextClick={this.handleBookingPanelCtaClick}
    >
    </AddAreaPopup>
    {/*)}*/}
    </>
  );
}

const styles = StyleSheet.create({
  ctaContainer: {
    right: '0',
    left: '0',
    // @ts-ignore
    position: 'fixed', //'absolute',
    bottom: '82px', // 0,
    padding: '16px',
    // 'background-color': theme.whiteBasic,
  }
});