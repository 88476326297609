import React, { PropsWithChildren, useCallback, useState, useEffect, useRef, MouseEvent } from 'react';
import {
  // Text,
  View,
  // ScrollView,
  // useWindowDimensions,
  StyleSheet,
  // TextStyle,
  // ViewStyle,
  // Dimensions,
  // Animated,
} from 'react-native';
import { Booking } from '../../types';
import clsx from 'clsx';
import { useAppState } from '../../state';

import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';

import Button from '@material-ui/core/Button';
// import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
// import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';
// import { ReactComponent as GoogleLogo } from './google-logo.svg';
import { Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
import RoundedContainer from '../RoundedContainer/RoundedContainer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

// import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

import SlidingUpPanel from 'rn-sliding-up-panel';
import { Drawer as TruBeDrawer } from '../Drawer/Drawer';

// import InfiniteScroll from '../InfiniteScroll/InfiniteScroll';
import SessionDetails from '../SessionDetails/SessionDetails';
import RunningLatePopup from '../RunningLatePopup/RunningLatePopup';
import RunningLateSuccessDialog from './RunningLateSuccessDialog/RunningLateSuccessDialog';
import DeclineSessionDialog from './DeclineSessionDialog/DeclineSessionDialog';
import RateSessionDialog from '../RateSessionDialog/RateSessionDialog';
import ContactSupportDialog from '../ContactSupportDialog/ContactSupportDialog';
import PhoneNumberDialog from '../PhoneNumberDialog/PhoneNumberDialog';


// import LocationIcon from '../../icons/LocationIcon';
import ArrowRightIcon from '../../icons/ArrowRightIcon';
// import PlusIcon from '../../icons/PlusIcon';
import LoaderSpinnerIcon from '../../icons/LoaderSpinnerIcon';
// import LoaderSpinnerBigIcon from '../../icons/LoaderSpinnerBigIcon';
// import UserPlaceholderInPerson from '../../icons/UserPlaceholderInPerson';
// import UserPlaceholderOnline from '../../icons/UserPlaceholderOnline';

import BottomNavigation from '../BottomNavigation/BottomNavigation';

import SessionInPersonListItemIcon from './SessionInPersonListItemIcon';
import SessionOnlineListItemIcon from './SessionOnlineListItemIcon';

import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useParams, useHistory } from 'react-router-dom';
// import { matchPath } from 'react-router';

import moment from 'moment';

// import { getViewportHeight } from '../../utils';
import { PAGE_SIZE } from '../../constants';

// const styles = {
//   container: {
//     flex: 1,
//     'background-color': 'rgb(255, 255, 255)',
//     'align-items': 'center',
//     'justify-content': 'center',
//     'border-radius': '8px',
//     height: '100%'
//   }
// };

// type PanelStyle = {
//   backdrop: ViewStyle;
//   container: ViewStyle;
//   // title: TextStyle;
//   // icon: ImageStyle;
// };


// const panelStyles = StyleSheet.create<PanelStyle>({
//   backdrop: {
//     // position: 'fixed',
//     position: 'absolute',
//     'z-index': 1000,
//   },
//   container: {
//     // position: 'fixed',
//     position: 'absolute',
//     'z-index': 1000,
//   },
// });

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    // display: block;
    'box-sizing': 'border-box',
    // margin-left: auto;
    // margin-right: auto;
    'padding-left': '16px',
    // padding-left: 16px;
    'padding-right': '16px',
    'margin-top': '24px',
  },
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  errorMessage: {
    color: 'red',
    display: 'flex',
    'align-items': 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      'margin-right': '0.4em',
    },
  },
  gutterBottom: {
    'margin-bottom': '1em',
  },
  gutterTopDoubleMedium: {
    'margin-top': '2em',
  },
  gutterBottomDoubleMedium: {
    'margin-bottom': '2em',
  },
  gutterBottomExtraExtraLarge: {
    'margin-bottom': '80px'
  },
  passcodeContainer: {
    'min-height': '120px',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  loadMoreButton: {
    'margin-top': '24px',
    'margin-bottom': '24px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  whiteBasicColor: {
    color: theme.whiteBasic,
  },
  trueblackBasicColor: {
    color: theme.trueblackBasic,
  },
  redColor: {
    color: theme.red,
  },
  greyColor: {
    color: theme.grey,
  },
  greyMainColor: {
    color: theme.greyMain,
  },
  root: {
    'justify-content': 'center',
    color: theme.trueblackBasic,
    'min-width': '24px'
  },
  rootArrow: {
    color: theme.red,
    'min-width': '24px'
  },

  ctaContainer: {
    right: '0',
    left: '0',
    position: 'absolute',
    bottom: '0',
    padding: '16px',
    'background-color': theme.whiteBasic,
  },

  loaderSpinner: {
    display: 'flex',
    'margin-right': '4px',
    // 'justify-content': 'center',
    // 'align-items': 'center',
    // position: 'relative',
    // height: 64,
    // height: 0,
    // 'margin-top': 24,
    // 'margin-bottom': '24px',

    "& > svg": {
      right: '19px',
      // transform: 'translateX(-50%)',
      top: '19px',
      position: 'absolute',
      'pointer-events': 'none',
      animation: '$spin 1s linear infinite',
      display: 'inline-block',
      'vertical-align': 'text-bottom',
      fill: 'currentColor',
      'z-index': 2,
    }
  },

  "@keyframes spin": {
    "100%": {
      'webkit-transform': 'rotate(360deg)',
      transform: 'rotate(360deg)',
    }
  },
}));

interface SessionsPageProps {
  sessionsToShow: string;
  // // open: boolean;
  // title: string;
  // className?: string;
  // trainerName?: string;
  // rating?: number;
  // // sessionType: string;
  // // onClick(): void;
  // // onClose(): void;
  // onRatingSet(rating: number): void;
}

export default function SessionsPage({ sessionsToShow }: PropsWithChildren<SessionsPageProps>) {
  const classes = useStyles();
  const {
    user,
    getBooking,
    setBooking,
    booking,
    upcomingBookings,
    completedBookings,
    getUpcomingBookings,
    getCompletedBookings,
    // isAuthReady,
    isFetching,
    isBookingsFetching,
    runningLate,
    // declineSession,
    cancelSession,
    hasMoreBookings,
    pageLoaded,
  } = useAppState();
  const history = useHistory();
  const location = useLocation<{ from: Location }>();
  const { BookingId } = useParams<{ BookingId?: string }>();
  // const [email, setEmail] = useState('');
  // const [password, setPassword] = useState('');
  // const [passcode, setPasscode] = useState('');
  // const [booking, setBooking] = useState(null);
  const [bookingId, setBookingId] = useState<string | null>(null);
  const [, setIsOpen] = useState<boolean>(false);
  const [bookingStatus, setBookingStatus] = useState('HAS_A_PARTNER');
  // const [sessionType, setSessionType] = useState('');
  // const [sessionsToShow, setSessionsToShow] = useState('upcoming');
  const [sessionDetailsDrawerOpen, setSessionDetailsDrawerOpen] = useState<boolean>(false);
  const [runningLatePopupOpen, setRunningLatePopupOpen ] = useState(false);
  const [runningLateSuccessOpen, setRunningLateSuccessOpen ] = useState(false);
  const [rateSessionOpen, setRateSessionOpen] = useState(false);
  const [contactSupportDialogOpen, setContactSupportDialogOpen] = useState(false);
  const [phoneNumberDialogOpen, setPhoneNumberDialogOpen] = useState(false);
  const [declineSessionDialogOpen, setDeclineSessionDialogOpen] = useState(false);
  const [declineSuccess, setDeclineSuccess] = useState(false);
  // const [authError, setAuthError] = useState<Error | null>(null);

  // const isAuthEnabled = true; // Boolean(process.env.REACT_APP_SET_AUTH);

  // const { height: deviceHeight } = useWindowDimensions();

  let panelRef = useRef<SlidingUpPanel>(null);
  let scrollParentRef = useRef(null);

  // TODO: make as useSessionsToShow hook
  useEffect(() => {
    // const match = matchPath(location.pathname, {
    //   path: "/sessions/completed",
    //   // exact: true,
    //   strict: false
    // });

    // if (match) {
    //   setSessionsToShow('completed');
    // } else {
    //   setSessionsToShow('upcoming');
    // }
  }, [location.pathname]);

  useEffect(() => {
    if (BookingId) {
      setBookingId(BookingId);

      getBooking(Number(BookingId));

      setSessionDetailsDrawerOpen(true);
      // if (user?.displayName) {
      //   setStep(Steps.deviceSelectionStep);
      // }
    } else {
      setBookingId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, BookingId]);

  useEffect(() => {
    if (sessionsToShow === 'upcoming') {
      getUpcomingBookings();
    } else {
      getCompletedBookings();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionsToShow/*, getUpcomingBookings, getCompletedBookings can't use it, because it would be loading indefinetely*/]);

  // useEffect(() => {
  //   if (bookingId) {
  //     // panelRef.current && panelRef.current.show();
  //     setSessionDetailsDrawerOpen(true);
  //   } else if (sessionDetailsDrawerOpen) {
  //     setSessionDetailsDrawerOpen(false);
  //   }

  // }, [bookingId, sessionDetailsDrawerOpen, /*, booking*/]);

  // useEffect(() => {
  //   if (!declineSessionDialogOpen) {
  //     setDeclineSuccess(false); // Reset state
  //   }
  // }, [declineSessionDialogOpen]);


  // const setRef = useCallback((ref) => {
  //   panelRef.current = ref.current;
  // }, []);

  // const login = () => {
  //   setAuthError(null);
  //   signIn?.(email, password)
  //     .then(() => {
  //       history.replace(location?.state?.from || { pathname: '/', search: window.location.search });
  //     })
  //     .catch(err => {
  //       setAuthError(err);
  //       console.log(err);
  //     });
  // };

  // const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   login();
  // };

  // const handleClick = (event: MouseEventHandler<HTMLDivElement>) => {
  //  // const handleClick = (event: MouseEvent<HTMLElement>, index: number) => {

  const loadMoreBookings = useCallback((page = 0) => {
    // const { userId } = this.props; // We have it here because, it's been invoked in componentDidUpdate
    const size = PAGE_SIZE;
    const sort = 'startTime,desc';
    const filter = '(status==COMPLETED,status==*CANCELLED*);status!=CANCELLED_REFUNDED;status!=UNKNOWN';

    if (isFetching) {
        return;
    }

    getCompletedBookings({
      page,
      size,
      sort,
      filter
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);

  const handleCtaClick = () => {
    if (bookingStatus === 'HAS_A_PARTNER') {

      setBookingStatus('IN_PROGRESS');
    } else {
      setBookingStatus('HAS_A_PARTNER');
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleBookingClick = useCallback((bookingId) => {
    return (e: MouseEvent<HTMLInputElement>) => {
      e.preventDefault() // we can all this directly here now!

      setIsOpen(true);

      // panelRef.current && panelRef.current.show();
      setSessionDetailsDrawerOpen(true);

      history.push({ pathname: `/sessions/${sessionsToShow === 'completed' ? 'completed/' : ''}${bookingId}` });
    }
  }, [history, sessionsToShow]);

  const handleSessionDetailsPopupClose = useCallback(() => {
    if (runningLatePopupOpen) {
      setRunningLatePopupOpen(false);

      return;
    }

    if (sessionDetailsDrawerOpen) {
      // @ts-ignore
      // panelRef?.current && panelRef?.current?.hide();
      setSessionDetailsDrawerOpen(false);
      setBooking(null);

      // history.push({ pathname: '/sessions' });
      history.push({ pathname: `/sessions${sessionsToShow === 'completed' ? '/completed' : ''}` });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, sessionDetailsDrawerOpen, runningLatePopupOpen, sessionsToShow]);

  const handleBottomReached = useCallback(() => {
    // panelRef.current && panelRef.current.hide();
    if (sessionDetailsDrawerOpen) {
      setSessionDetailsDrawerOpen(false);
      setBooking(null);
      history.push({ pathname: `/sessions${sessionsToShow === 'completed' ? '/completed' : ''}` });
    }
    // setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, sessionDetailsDrawerOpen, sessionsToShow]);

  const handleRunningLateClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setRunningLatePopupOpen(true);

    // history.push({ pathname: '/sessions' });
  }, [setRunningLatePopupOpen]);

  const handleRunningLateSubmit = useCallback((e: MouseEvent<HTMLButtonElement>, delayInMinutes?: number) => {
    if (user && booking) {
      runningLate(user?.id!, booking?.id!, delayInMinutes)
        .then(() => {
          setRunningLateSuccessOpen(true);
        });

      // onRunningLateClick && onRunningLateClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, booking]);

  const handleDeclineSessionClick = useCallback(() => {
    setDeclineSessionDialogOpen(true);

    // history.push({ pathname: '/sessions' });
  }, []);

  const handleContactSupportClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    setContactSupportDialogOpen(true);
  }, []);

  const handlePhoneNumberClick = useCallback((e: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    setPhoneNumberDialogOpen(true);
  }, []);

  const handleDecline = useCallback(() => {
    if (user && booking) {
      cancelSession(user?.id!, booking?.id!)
        .then(() => {
          setDeclineSuccess(true);
        });

      // onRunningLateClick && onRunningLateClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, booking]);

  const handleRunningLatePopupClose = useCallback(() => {
    setRunningLatePopupOpen(false);
  }, []);


  // No used
  // const closeSessionDetailsDrawer = useCallback(() => {
  //   setSessionDetailsDrawerOpen(false);
  // }, []);
  // //   e.preventDefault();
  // //   login();
  //  history.push('session-details');
  // };

  // if (user || !isAuthEnabled) {
  //   history.replace('/');
  // }

  // if (!isAuthReady) {
  //   return null;
  // }

  const getProductType = (productId: string) => {
    return productId.includes('_LS') ? 'LS' : 'F2F';
  };

  const bookings = sessionsToShow === 'upcoming' ? upcomingBookings : completedBookings;

  var loadMoreButtonShow = sessionsToShow === 'completed' && (/*pageLoaded !== undefined && pageLoaded < 1 && */completedBookings?.length! >= PAGE_SIZE || hasMoreBookings); // pageLoaded !== undefined && pageLoaded < 1 && completedBookings?.length && completedBookings?.length >= PAGE_SIZE && !isBookingsFetching;

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderRow = (booking: Booking) => (
    <React.Fragment key={booking.id}>
      <ListItem
        button
        style={{
          paddingLeft: "0",
          paddingRight: "0",
          // display="flex"
          // alignItems: "center",
          justifyContent: "space-between",
          // flex: "1"
        }}
        onClick={handleBookingClick(booking.id)}
      >
        {getProductType(booking.product.id) === 'LS'
          ? <SessionOnlineListItemIcon pictureUrl={booking?.user?.userDetail.pictureUrl} />
          : <SessionInPersonListItemIcon pictureUrl={booking?.user?.userDetail.pictureUrl} />
        }
        <ListItemText
          primaryTypographyProps={{
            variant: "h3",
            // display: "inline",
            // color: "primary"
            style: {color: '#111418'}
          }}
          secondaryTypographyProps={{
            variant: "body1",
            // display: "inline",
            // color: "primary",
            style: {color: '#111418'}
          }}
          primary={`${booking.user?.firstName} ${booking.user?.familyName}`}
          secondary={moment(Number(booking.startTime)).format('ddd, DD MMM [at] HH:mm A')}
          // secondary="Thu, 12 Aug at 14:00"
          style={{ marginLeft: '16px' }}
        />
        <ListItemIcon
          classes={{
            root: classes.rootArrow,
          }}
        >
          <ArrowRightIcon />
        </ListItemIcon>
      </ListItem>
      <Divider light />
      </React.Fragment>
  );

  return (
    <>
    <ScrollToTopOnMount />
    <div>
    <Container style={{ paddingTop: '32px' }}>
      <Typography
        variant="h2"
        className={clsx(classes.gutterBottomDoubleMedium, classes.greyMainColor)}
        style={{ marginTop: '0', marginBottom: '32px' }}
      >
        {sessionsToShow === 'upcoming' ? 'Upcoming' : 'Completed'} sessions
      </Typography>
    </Container>
    <RoundedContainer
      attachRef={scrollParentRef}
      style={{
        height: 'calc(100% - 104px)',
        // height: 'calc(100vh - 104px)',
        // 'min-height': '-webkit-fill-available',
        // 'margin-bottom': 'env(safe-area-inset-bottom)',
        'padding-bottom': '82px',
      }}
      // innerContainerStyle={{ height: '100%' }}
    >

    {/*<Typography variant="h3" className={clsx( classes.gutterBottom, classes.greyColor)}>
        4 postcodes covered:
    </Typography>*/}

          {/*<ScrollView
            // ref={scrollParentRef}
            style={{
              width: '100%',
              overflow: 'scroll'
            }}
          >*/}
      <div className={classes.container}>
        {bookings && bookings?.length ? (
          <>
          <List component="nav" aria-label="mailbox folders" disablePadding className={loadMoreButtonShow ? '' : classes.gutterBottomExtraExtraLarge}>
          {/*<List component="nav" aria-label="mailbox folders" disablePadding className={classes.gutterBottomExtraExtraLarge}>*/}
            {bookings.map(renderRow)}
          </List>
          {loadMoreButtonShow
            ? <View style={_styles.ctaContainer}>
            <Button
                variant="contained"
                color="primary"
                // type="submit"
                disabled={isBookingsFetching}
                className={classes.loadMoreButton}
                // className={'btn btn-trube tb-spacing-top-medium tb-spacing-md-top-extra-large'}
                onClick={ () => loadMoreBookings(pageLoaded! + 1) }
              >
                Load more
                {isBookingsFetching && (
                <div className={clsx(classes.loaderSpinner)}>
                  <LoaderSpinnerIcon />
                </div>
                )}
              </Button>
              </View>
            : null
          }
          </>
          ) : (
            <Typography
              variant="body1"
              // className={clsx(classes.gutterBottomDoubleMedium, classes.greyMainColor)}
              style={{ marginTop: '8px', textAlign: 'center' }}
            >
              You have no {sessionsToShow} sessions
            </Typography>
          )}
          {/*<Divider light />*/}
      </div>

      {/*<Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          type="submit"
          // disabled={!password.length}
          className={classes.addAreaCtaButton}
        >
          <PlusIcon />
          Add Area
        </Button>
      </Grid>*/}


      {/*<form onSubmit={handleSubmit}>
        <Grid container justifyContent="space-between">
          <div className={classes.passcodeContainer}>
            <InputLabel shrink htmlFor="input-passcode">
              Email
            </InputLabel>
            <TextField
              id="input-email"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              type="text"
              variant="outlined"
              size="small"
            />
            <InputLabel shrink htmlFor="input-passcode">
              Password
            </InputLabel>
            <TextField
              id="input-passcode"
              onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
              size="small"
            />
            <div>
              {authError && (
                <Typography variant="caption" className={classes.errorMessage}>
                  <ErrorOutlineIcon />
                  {authError.message}
                </Typography>
              )}
            </div>
          </div>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={!password.length}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </Grid>
      </form>*/}

    </RoundedContainer>
    </div>
    <BottomNavigation />

    <TruBeDrawer
      // ref={panelRef}
      // attachRef={setRef}
      attachRef={panelRef}
      allowDragging={true}
      // disabled={false}
      title={runningLatePopupOpen ? "Running late" : "Session details"}
      isOpen={sessionDetailsDrawerOpen}
      containerStyle={{ height: 'calc(100% - 42px)', backgroundColor: 'rgb(255, 255, 255)' }}
      // innerContainerStyle={{ maxHeight: 'calc(100% + 8px)', 'padding-bottom': 24, overflow: 'scroll' }}


      // activeState={this.props.bookingPanelState}
      // bookingDate={this.state.bookingDate}
      // onDateSelect={this.handleDateTimeChange}
      // onDateSelect={this.handleDirectBookingTimeChange}
      onBottomReached={handleBottomReached}
      onClose={handleSessionDetailsPopupClose}
      // onNextClick={this.handleBookingPanelCtaClick}
    >
      {(bookingId && sessionDetailsDrawerOpen && !runningLatePopupOpen) ? (
      <SessionDetails
        booking={booking}
        status={bookingStatus}
        declineStatus={declineSuccess}
        // sessionType={sessionType}
        onRunningLateClick={handleRunningLateClick}
        onDeclineSessionClick={handleDeclineSessionClick}
        onClick={handleCtaClick}
        onPhoneNumberClick={handlePhoneNumberClick}
        onContactSupportClick={handleContactSupportClick}
        onClose={handleSessionDetailsPopupClose}
        // onContactSupportClick={handleDeclineContactSupportClick}
      />): runningLatePopupOpen ? (
        <RunningLatePopup
          open={runningLatePopupOpen}
          onClose={handleRunningLatePopupClose}
          onSubmit={handleRunningLateSubmit}
        />
        ) : <div />
      }
    </TruBeDrawer>

    {/*{false && runningLatePopupOpen && (
    <TruBeDrawer
      // disabled={false}
      title="Running late"
      allowDragging={true}
      isOpen={runningLatePopupOpen}
      snappingPoints={[deviceHeight - 24]}
      containerStyle={{ height: 'calc(100% - 50px)' }}
      // activeState={this.props.bookingPanelState}
      // bookingDate={this.state.bookingDate}
      // onDateSelect={this.handleDateTimeChange}
      // onDateSelect={this.handleDirectBookingTimeChange}
      onClose={handleRunningLatePopupClose}
      // onNextClick={this.handleBookingPanelCtaClick}
    >
      <RunningLatePopup
        open={runningLatePopupOpen}
        onClose={handleRunningLatePopupClose}
        onSubmit={handleRunningLateSubmit}
      />
    </TruBeDrawer>
    )}*/}
    <RunningLateSuccessDialog
      open={runningLateSuccessOpen}
      onClose={() => {
        setRunningLateSuccessOpen(false);
        setRunningLatePopupOpen(false);
        // setMenuOpen(false);
      }}
    />
    <DeclineSessionDialog
      open={declineSessionDialogOpen}
      success={declineSuccess}
      onConfirm={handleDecline}
      onClose={() => {
        // setRunningLateSuccessOpen(false);
        setDeclineSessionDialogOpen(false);
        // setMenuOpen(false);
      }}
    />
    <RateSessionDialog
      open={rateSessionOpen}
      onClose={() => setRateSessionOpen(false)/* setMenuOpen(false);*/}
    />
    <ContactSupportDialog
      open={contactSupportDialogOpen}
      onClose={() => setContactSupportDialogOpen(false)}
    />
    <PhoneNumberDialog
      open={phoneNumberDialogOpen}
      phoneNumber={booking?.user?.userDetail?.phoneNumber || '+44(0) 745 978 3573'}
      onClose={() => setPhoneNumberDialogOpen(false)}
    />
    </>
  );
}

const _styles = StyleSheet.create({
  ctaContainer: {
    // right: '0',
    // left: '0',
    // position: 'absolute',
    // bottom: '0',
    // padding: 16,
    backgroundColor: 'rgb(255, 255, 255)'
  }
});
