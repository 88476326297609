import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import reportWebVitals from './reportWebVitals';
// import AppStateProvider, { useAppState } from './state';
// import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
// import ErrorDialog from './components/ErrorDialog/ErrorDialog';
// import LoginPage from './components/LoginPage/LoginPage';
// import PasswordResetPage from './components/LoginPage/PasswordReset';
// import PasswordResetInstructionsSent from './components/LoginPage/PasswordResetInstructionsSent';
// import PasswordResetNewPassword from './components/LoginPage/PasswordResetNewPassword';
// import MainPage from './components/MainPage/MainPage';
// import AvailabilityCalendarPage from './components/AvailabilityCalendarPage/AvailabilityCalendarPage';
// import AreasPage from './components/AreasPage/AreasPage';
// import SessionsPage from './components/SessionsPage/SessionsPage';
// import SessionDetails from './components/SessionDetails/SessionDetails';
// import RateSession from './components/RateSession/RateSession';
// // import RunningLatePopup from './components/RunningLatePopup/RunningLatePopup';
// import AppFeedbackPopup from './components/AppFeedbackPopup/AppFeedbackPopup';

// import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
// import { ChatProvider } from './components/ChatProvider';
// import { ParticipantProvider } from './components/ParticipantProvider';
// import { VideoProvider } from './components/VideoProvider';
// import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

import momentLocalizer from './components/AvailabilityPicker/momentLocalizer';
// import moment from 'moment';


momentLocalizer();



// const VideoApp = () => {
//   const { error, setError } = useAppState();
//   const connectionOptions = useConnectionOptions();

//   return (
//     <VideoProvider options={connectionOptions} onError={setError}>
//       <ErrorDialog dismissError={() => setError(null)} error={error} />
//       <ParticipantProvider>
//         <ChatProvider>
//           <App />
//         </ChatProvider>
//       </ParticipantProvider>
//     </VideoProvider>
//   );
// };

export const ReactApp = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <App />
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register(); we register the sw in ServiceWorkerWrapper

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
