import React from 'react';
import { View } from 'react-native';
// import clsx from 'clsx';
// import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import { Theme } from '@material-ui/core';
// import IntroContainer from '../IntroContainer/IntroContainer';
// import TextField from '@material-ui/core/TextField';
// import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import Box from '@material-ui/core/Box';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';

// import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
// import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
// import { useAppState } from '../../state';
import { makeStyles, withStyles } from '@material-ui/core/styles';
// import { NavLink } from 'react-router-dom';
// import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import BottomNavLink from './BottomNavLink/BottomNavLink';

import UserIcon from '../../icons/UserIcon';
import CalendarPlusIcon from '../../icons/CalendarPlusIcon';
import LocationIcon from '../../icons/LocationIcon';
import ListIcon from '../../icons/ListIcon';


export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

const useStyles = makeStyles((theme: Theme) => ({
  // googleButton: {
  //   background: 'white',
  //   color: 'rgb(0, 94, 166)',
  //   'border-radius': '4px',
  //   border: '2px solid rgb(2, 122, 197)',
  //   margin: '1.8em 0 0.7em',
  //   'text-transform': 'none',
  //   'box-shadow': 'none',
  //   padding: '0.3em 1em',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  //   '&:hover': {
  //     background: 'white',
  //     'box-shadow': 'none',
  //   },
  // },
  // errorMessage: {
  //   color: 'red',
  //   display: 'flex',
  //   'align-items': 'center',
  //   margin: '1em 0 0.2em',
  //   '& svg': {
  //     'margin-right': '0.4em',
  //   },
  // },
  // gutterBottom: {
  //   'margin-bottom': 4,//'0.5em',
  // },
  // gutterBottomBase: {
  //   'margin-bottom': 16
  // },
  // gutterBottomLarge: {
  //   'margin-bottom': 24
  // },
  // gutterBottomExtraExtraLarge: {
  //   'margin-bottom': 80
  // },
  // passcodeContainer: {
  //   'min-height': '120px',
  // },
  // submitButton: {
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%',
  //   },
  // },

  // profilePictureContainer: {
  //   // width: '100%',
  //   display: 'flex',
  //   'justify-content': 'center',
  //   'align-items': 'center',
  //   'border-radius': '10px',
  //   // border: `solid ${theme.palette.grey[400]}`,
  //   // '&.selected': {
  //   //   border: `solid ${theme.palette.primary.main}`,
  //   //   '& svg': {
  //   //     color: `${theme.palette.primary.main}`,
  //   //   },
  //   // },
  // },

  // partnerProfilePictureImage: {
  //   width: '163px',
  //   height: '163px',
  //   // position: 'absolute',
  //   top: 0,
  //   bottom: '0',
  //   left: '0',
  //   right: '0',
  //   'object-fit': 'cover',
  //   'border-radius': '10px',
  //   // border: `solid ${theme.palette.grey[400]}`,
  //   // '&:hover': {
  //   //   cursor: 'pointer',
  //   //   '& svg': {
  //   //     color: `${theme.palette.primary.main}`,
  //   //   },
  //   //   '& $thumbOverlay': {
  //   //     visibility: 'visible',
  //   //   },
  //   // },
  //   // '&.selected': {
  //   //   border: `solid ${theme.palette.primary.main}`,
  //   //   '& svg': {
  //   //     color: `${theme.palette.primary.main}`,
  //   //   },
  //   // },
  // },

  // greyColor: {
  //   color: theme.grey,
  // },

  // greyMainColor: {
  //   color: theme.greyMain,
  // },

  // redColor: {
  //   color: theme.red,
  // },

  // hidden: {
  //   display: 'none'
  // },

  // roundedTopContainer: {
  //   'margin-top': -8,
  //   'padding-top': 24,
  //   'padding-left': 16,
  //   'padding-right': 16,
  //   'border-top-right-radius': '8px',
  //   'border-top-left-radius': '8px',
  //   'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
  //   'background-color': 'rgb(255, 255, 255)',
  //   position: 'relative'
  // },

  // linkLikeButton: {
  //   'text-decoration': 'underline'
  // },

  bottomNavigationContainer: {
    position: 'fixed',
    bottom: '0',
    'background-color': theme.whiteBasic,
    'box-shadow': '0px 0px 32px rgba(0, 0, 0, 0.1)',
    'z-index': 1003,
  },
  flexContainer: {
    display: 'flex',
    'flex-direction':  'row',
    'justify-content': 'space-evenly',
    'flex-grow': 1,
    padding: '0',
  },
  root: {
    'justify-content': 'center'
  },

  navLink: {
    width: '100%',
    'text-decoration': 'none',
    'text-align': 'center',
    // "&:hover": {
    //   color: '#E94E32',
    // }
  },

  navLinkActive: {
    color: '#E94E32',

    "&$navLink .MuiListItemIcon-root": {
      color: theme.red,
    }
  },
  navLinkUnselected: {
   color: '#303740',
  },
}));

const MyListItem = withStyles((theme) => ({
  root: {
    'justify-content': 'center',
    "& .MuiListItemIcon-root": {
      color: theme.greyMain,
    },
    // "& .MuiListItemText-primary": {
    //   color: theme.grey,
    // },
    "&$selected": {
      'background-color': 'transparent',
      "&:hover": {
        'background-color': 'transparent',
      },
    },
    //   // 'background-color': "red",
    //   color: theme.red,
    //   "& .MuiListItemIcon-root": {
    //     color: theme.red,
    //   },
    //   "&:hover": {
    //     'background-color': 'transparent',
    //   },
    // },
    "&:hover": {
      'background-color': 'transparent',
    },

    // Hovers disabled
    // "&$selected:hover": {
    //   'background-color': 'transparent',
    //   // 'background-color': "purple",
    //   color: theme.red,
    //   // color: "white",
    //   "& .MuiListItemIcon-root": {
    //     color: theme.red,
    //   }
    // },
    // "&:hover": {
    //   'background-color': 'transparent',
    //   // 'background-color': "blue",
    //   color: theme.red,
    //   "& .MuiListItemIcon-root": {
    //     color: theme.red,
    //   }
    // },



    // "& .MuiListItemText-root": {
    //   // root: {
    //     // 'text-decoration': 'none'
    //   // },
    //   'text-decoration': 'none',
    //   color: "brown"
    // },
    // "& .MuiListItemText-root:active": {
    //   color: "white"
    // },
    // "& .MuiListItemText-root:focus": {
    //   color: "white"
    // }
  },
  selected: {},
  // typography: {
  //   root: {
  //     'text-decoration': 'none',
  //     color: "red"
  //   }
  // },

}))(ListItem);


// const styles = {
//   listItemText: {
//     // color: 'white',
//     'text-decoration': 'none'
//   },
// }

// const navLinkStyles = {
//   // "& .nav-link": {
//     color: ""
//   // },
// };

export default function BottomNavigation() {
  const classes = useStyles();
  // const { user } = useAppState();
  // const { getAudioAndVideoTracks } = useVideoContext();
  // const { URLRoomName } = useParams<{ URLRoomName?: string }>();

  // const [step, setStep] = useState(Steps.deviceSelectionStep); // useState(Steps.roomNameStep);

  // const [name, setName] = useState<string>(user?.displayName || '');
  // const [roomName, setRoomName] = useState<string>('');

  // const [mediaError, setMediaError] = useState<Error>();

  // const [selectedIndex, setSelectedIndex] = useState(1);

  // const searchParams = new URLSearchParams(window.location.search);
  // const bookingId = searchParams.get('bookingId');

  // const history = useHistory();


  // useEffect(() => {
    // window.location.pathname === '/coverage';

  //   if (URLRoomName) {
  //     setRoomName(URLRoomName);
  //     if (user?.displayName) {
  //       setStep(Steps.deviceSelectionStep);
  //     }
  //   }
  // }, [user, URLRoomName]);
  // }, [user, URLRoomName]);

  // useEffect(() => {
  //   if (step === Steps.deviceSelectionStep && !mediaError) {
  //     getAudioAndVideoTracks().catch(error => {
  //       console.log('Error acquiring local media:');
  //       console.dir(error);
  //       setMediaError(error);
  //     });
  //   }
  // }, [getAudioAndVideoTracks, step, mediaError]);

  // const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
  //   // @ts-ignore
  //   if (!window.location.origin.includes('twil.io') && !window.STORYBOOK_ENV) {
  //     window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
  //   }
  //   setStep(Steps.deviceSelectionStep);
  // };

  // const userId = user ? user?.id : '';

  // const handleListItemClick = (event: MouseEvent<HTMLElement>, index: number) => {
  //   setSelectedIndex(index);

  //   switch (index) {
  //     case 0: //
  //       history.push("/main-page");
  //       break;
  //     case 2:
  //       history.push("/areas-page");
  //       break;
  //     case 3:
  //       history.push("/sessions-page");
  //   }
  // };

  return (
    <Container className={classes.bottomNavigationContainer}>
      <View
        style={{
          flexDirection: "row",
          // alignItems: "center"
          paddingTop: '16px',
          paddingBottom: '16px'
        }}
      >
      <List component="nav" aria-label="mailbox folders" className={classes.flexContainer}>
        <MyListItem
          button
          style={{ padding: '0' }}
          // selected={selectedIndex === 0}
          // onClick={(event) => handleListItemClick(event, 0)}
        >
          <BottomNavLink
            to="/"
            linkIcon={<UserIcon />}
            text='Home'
          />
        {/*<ListItem button style={{ 'padding-left': '0', 'padding-right': '0' }}>*/}
        </MyListItem>
        <MyListItem
          button
          style={{ padding: '0' }}
          // selected={selectedIndex === 1}
          // onClick={(event) => handleListItemClick(event, 1)}
        >
          <BottomNavLink
            to="/availability"
            linkIcon={<CalendarPlusIcon />}
            text='Calendar'
          />
        </MyListItem>
        <MyListItem
          button
          style={{ padding: '0' }}
          // selected={selectedIndex === 2}
          // onClick={(event) => handleListItemClick(event, 2)}
        >
          <BottomNavLink
            to="/coverage"
            linkIcon={<LocationIcon />}
            text='Areas'
          />
        </MyListItem>
        <MyListItem
          button
          style={{ padding: '0' }}
          // selected={selectedIndex === 3}
          // onClick={(event) => handleListItemClick(event, 3)}
        >
          <BottomNavLink
            to="/sessions"
            linkIcon={<ListIcon />}
            text='Sessions'
          />
        </MyListItem>
      </List>
      </View>
    </Container>
  );
}
